import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';

import Link from '../../../core/Link';
import SolutionsPage from '../components/SolutionsPage';

import heritageLogo from '../../../../images/logos/Heritage-house-transparent-crop.svg';
import threeFallsLogo from '../../../../images/logos/three-falls-cove.png';
import eightDyer from '../../../../images/logos/8dyer-transparent.svg';
import trailsideLogo from '../../../../images/logos/Trailside-transparent-crop.svg';

import hero from './images/hero.png';
import support from './images/support_panel.png';
import payment from './images/payment_panel.png';
import simplicity from './images/simplicity_panel.png';
import booking_engine_home from './images/booking_engine_home.png';
import guest_experience from './images/guest_experience_panel.png';
import channel_manager_diagram from './images/channel_mgr_diagram.svg';

import CenteredCardWithImage from '../../../ui-library/Hero/CenteredCardWithImage';
import LogoScroll from '../../../ui-library/LogoScroll';
import SimpleFeatureGrid from '../../../ui-library/FeatureSections';
import TwoColumnCta from '../../../ui-library/TwoColumnCta';
import SimpleCTASection from '../../../ui-library/CTASections';
import Testimonial from '../../../ui-library/Testimonials/Testimonial';
import HeaderSection from '../../../ui-library/HeaderSection';
import SimpleCenteredTestimonial from '../../../ui-library/Testimonials/SimpleCenteredTestimonial';
import SimpleThreeColumn from '../../../ui-library/FeatureSections/SimpleThreeColumn';

const logos = [
  {
    src: heritageLogo,
  },
  {
    src: threeFallsLogo,
  },
  {
    src: eightDyer,
  },
  {
    src: trailsideLogo,
  },
];

export class BedAndBreakfastPage extends Component {
  render() {
    return (
      <SolutionsPage>
        <Helmet title="Bed &amp; Breakfast Management Software – ThinkReservations">
          <meta
            name="description"
            content="Bed &amp; breakfast property management software from ThinkReservations lets you focus on what really matters. Click here to learn more."
          />
          <script
            type="application/ld+json"
            children={JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Service',
              serviceType:
                'Bed and Breakfast Reservation Management Software Solutions',
              category:
                'Reservation Management Software for Small and Boutique Hotels',
              provider: {
                '@type': 'Organization',
                name: 'ThinkReservations',
              },
              areaServed: {
                '@type': 'Country',
                name: 'USA',
              },
            })}
          />
        </Helmet>

        <CenteredCardWithImage
          Img={() => (
            <img
              className="h-full w-full object-cover"
              src={hero}
              alt="An older woman and younger lady being shown aroung a property by a man."
            />
          )}
          heading="Reservation Management Software For Bed & Breakfasts + Inns"
          subheading="As an innkeeper, you thrive on providing a one-of-a-kind guest experience to every single visitor. We know it's no small feat to offer amenities, activities, and service that rival larger hotels while still maintaining the personal feel of an independent lodging property."
        />
        <SimpleFeatureGrid />
        <TwoColumnCta
          headline="What Will You Do With More Time?"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  We’ve yet to meet an innkeeper that daydreamed about handling
                  administrative work. While we can’t eliminate it from your
                  business, we can definitely help you streamline everything
                  from managing reservations, tracking guest info, handling
                  payments, sending guest communications, and much more.
                </p>
                <p>
                  With our powerful all-in-one{' '}
                  <Link
                    className="text-think-blue"
                    to="/products/booking-engine"
                  >
                    property management software
                  </Link>
                  , you'll spend less time on administrative tasks, while still
                  providing guests with everything they need to book and prepare
                  for their perfect stay.
                </p>{' '}
                Thoughtfully designed to be easy to use, we'll help make sure
                your team is trained and comfortable from day one. You won't
                need to set aside days or weeks to get up and running.
              </div>
            </Fragment>
          }
          Media={() => (
            <img
              className="w-full"
              src={simplicity}
              alt="A customer arriving at the front desk."
            />
          )}
        />
        <div className="my-32"></div>
        <SimpleCenteredTestimonial
          quote={`We are so pleased to partner with Think - start to finish, there was never a doubt that we were in good hands. Every effort was made to make our onboarding process as seamless as possible and we are so appreciative for the support, guidance and all the attention to detail. The team is absolutely amazing, top notch customer service, and exceptional support.`}
          author="Donna + Asim Khawaja"
          position="Greenview Manor B&B"
        />
        <div className="my-32"></div>
        <TwoColumnCta
          mediaLeft={true}
          headline="Make a Great First Impression on Your Guests "
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  Our{' '}
                  <Link
                    className="text-think-blue"
                    to="/products/booking-engine"
                  >
                    mobile-responsive booking engine.
                  </Link>{' '}
                  gives your guests a booking experience that's fast, easy, and
                  consistent with your brand. Whether they’re using their
                  smartphone, laptop, tablet, or desktop computer, they’ll be
                  able to search real-time availability and pricing. And you’ll
                  love the automated deposit collection and personalized email
                  confirmation.
                </p>{' '}
                <p>
                  If you’re looking to increase revenue (and who isn’t?), one of
                  the best ways to get started is through special rates,
                  packages, and extras like flowers and wine. Our booking engine
                  makes it so easy to let guests customize their reservation
                  during and after the booking process.
                </p>
              </div>
            </Fragment>
          }
          Media={() => (
            <img
              className="w-full"
              src={guest_experience}
              alt="A screenshot of the booking engine showing a room."
            />
          )}
        />
        <div className="my-24"></div>
        <SimpleCTASection
          Img={() => (
            <img
              className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
              src={booking_engine_home}
              alt="App screenshot showing the landing page for the booking engine."
            />
          )}
        />
        <div className="my-24"></div>
        <TwoColumnCta
          mediaLeft={true}
          headline="Bye Bye Double-Bookings"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  Are you tired of worrying about getting double bookings? We
                  know this is the most common reason why business owners don’t
                  work with OTAs. But that means missing out on the benefits
                  they offer - getting thousands of eyes on your independent
                  property.
                </p>
                <p>
                  With our
                  <Link
                    className="text-think-blue"
                    to="/products/channel-manager"
                  >
                    {' '}
                    channel manager
                  </Link>
                  , your third-party OTA listings are always up-to-date. We have
                  real-time two-way connections with the sites you need so you
                  stay ahead of the curve. Send availability and pricing to
                  Booking.com, Expedia, Airbnb, HotelTonight, Tripadvisor, and
                  more and never worry about a double-booking again.
                </p>
                <p>
                  We also believe YOU should be in control of your OTA
                  relationships. That’s why we offer the flexibility you need to
                  share only what you want, when you want. Optimize your
                  marketing while maximizing your revenue.
                </p>
              </div>
            </Fragment>
          }
          Media={() => (
            <div className="bg-slate-50 p-8">
              <img
                className="w-full"
                src={channel_manager_diagram}
                alt="A diagram outlining the several connections available in the channel manager."
              />
            </div>
          )}
        />
        <div className="my-44"></div>
        <TwoColumnCta
          headline="Make Data-Driven Business Decisions"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  You’ve got a lot going on. Our{' '}
                  <Link
                    className="text-think-blue"
                    to="/resources/articles/reports"
                  >
                    robust reporting
                  </Link>{' '}
                  features make it simple to get a quick overview of your
                  hotel's performance or dive deep into the data to find areas
                  for improvement. And if you’re not sure where to start, we can
                  help you figure out which reports will help you get the most
                  out of your limited resources.
                </p>
                <p>
                  You'll especially love our{' '}
                  <Link
                    className="text-think-blue"
                    to="/resources/webinars/reports"
                  >
                    Booking pace report
                  </Link>
                  . It tells you how many rooms are booked for any given period
                  and compare with another timeframe. Using your historical
                  booking data, you can see patterns and make predictions for
                  the future. This report is essential for forecasting
                  occupancy, making adjustments on the fly, and planning your
                  marketing strategy… and helping you make more revenue!
                </p>
              </div>
            </Fragment>
          }
          Media={() => (
            <div className="bg-think-orange px-8">
              <Testimonial
                logo={null}
                avatar={null}
                name="Marty E."
                position="Flag House Inn"
                quote="Think has supported a 40% increase in business. We did a back to back to back demo of 3 systems and Think was easy winner. We worked with them to plan a fast go live after we bought the business."
              />
            </div>
          )}
        />
        <div className="my-44"></div>
        <TwoColumnCta
          mediaLeft={true}
          headline="Payment Processing Made Simple"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  {' '}
                  We offer free integrations with a variety of{' '}
                  <Link
                    className="text-think-blue"
                    to="/products/payment-processing"
                  >
                    payment processors
                  </Link>
                  , so you can take payments in the way that’s best for your
                  unique business. ThinkReservations connects with your
                  processor for fast, reliable, and secure deposits and
                  payments. Safely store credit card details so you can quickly
                  collect balances at check-in or charge for extras during their
                  stay. You even have the option to keep it on file for future
                  reservations!
                </p>
                <p>
                  We take security seriously and undergo a full
                  <Link
                    className="text-think-blue"
                    to="/articles/pci-compliance"
                  >
                    PCI Compliance
                  </Link>
                  audit annually.
                </p>
              </div>
            </Fragment>
          }
          Media={() => (
            <img
              className="w-full"
              src={payment}
              alt="A couple entering credit card info on a laptop."
            />
          )}
        />
        <div className="my-24"></div>
        <HeaderSection
          title="Bring Your Favorite Third-Party Tools Along"
          copy={null}
          children={
            <p className="max-w-3xl mt-5 mx-auto text-xl text-gray-500">
              Continue using the tools you love along with ThinkReservations. We
              offer{' '}
              <Link className="text-think-blue" to="/products/integrations">
                integrations
              </Link>{' '}
              with a variety of third-party software to make it even easier to
              run your business. With options like OTAs, point-of-sale, and
              guest communication, you’ll have everything you need seamlessly
              connected.
            </p>
          }
        />
        <div className="my-24"></div>
        <LogoScroll logos={logos} />
        <div className="my-44"></div>
        <TwoColumnCta
          mediaLeft={true}
          headline="Don’t Leave Your History Behind"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  Speaking of data, want to know one of the biggest excuses
                  business owners make for staying with a PMS they don’t love?
                  Fear of losing existing reservation and guest details. We've
                  got you covered! When you upgrade to ThinkReservations we’ll
                  bring over all of your historic data for you. Free of charge.
                </p>
                <p>
                  After successfully handling over 1000 data imports from a wide
                  range of property management systems, we know we can do the
                  same for you.
                </p>
              </div>
            </Fragment>
          }
          Media={() => (
            <div className="bg-think-orange px-8">
              <Testimonial
                logo={null}
                avatar={null}
                name="Hermann"
                position="MO - Luxury Hotel"
                quote="ThinkReservations is a booking revolution This system will make it so easy to keep your business running with minimal effort. All the day to day operations of managing your reservations simply takes care of itself."
              />
            </div>
          )}
        />
        <div className="my-44"></div>
        <h1 className="max-w-7xl mx-auto px-4 mt-1 block text-4xl text-center tracking-tight font-extrabold sm:text-5xl">
          Customer Support People Rave About
        </h1>
        <SimpleThreeColumn />
        <TwoColumnCta
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  Our team of Customer Success specialists (many with real-world
                  hospitality experience), are dedicated to providing you the
                  same level of service you give your guests. We want you to
                  feel confident that if you need anything, our team is here for
                  you.
                </p>
                <p>
                  When you become a client, you’ll receive comprehensive
                  onboarding, account setup, and training. And if you ever have
                  a question in the future, our online self-service support
                  center has articles and videos available anytime. Our US-based
                  customer support can be reached via email, phone, and chat,
                  all included in your subscription. We’ve even got emergency
                  phone support after-hours just in case.
                </p>
              </div>
            </Fragment>
          }
          Media={() => (
            <img
              className="w-full"
              src={support}
              alt="A happy customer talking with support."
            />
          )}
        />
        <div className="my-24"></div>
        <SimpleCenteredTestimonial
          quote={`The entire process, from speaking with a sales representative to installation and training to going live, was absolutely seamless on ThinkReservation's end. Would that all other digital transitions go as smoothly! Thank you very much!`}
          author="David Johnson"
          position="Tradewinds on the Bay"
        />

        <HeaderSection
          title="Supporting Independent Lodging Properties for Over 10 Years"
          copy={null}
          subtitle={null}
          children={
            <>
              <p className="max-w-3xl mt-5 mx-auto text-xl text-gray-500">
                <p>
                  We've been working with boutique hotels for over 10 years and
                  we're dedicated to helping you succeed. We understand the
                  unique challenges and opportunities you face as an independent
                  hotelier and we’re here to help you meet the evolving needs of
                  today's travelers.{' '}
                  <Link className="text-think-blue" to="/products/integrations">
                    Learn more about our team.
                  </Link>{' '}
                </p>
                <p>
                  Choose a property management solution that will help you grow
                  your business while delivering the exceptional experience your
                  guests have come to expect.
                </p>
              </p>
              <Link to="/request-a-demo">
                <button
                  className="block max-w-xl mx-auto w-full my-4 rounded-md border border-transparent px-5 py-3 bg-[#e27d4e] text-base font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 sm:px-10"
                  type="submit"
                >
                  Request a demo today
                </button>
              </Link>
            </>
          }
        />
        <div className="my-24"></div>
      </SolutionsPage>
    );
  }
}

export default BedAndBreakfastPage;
